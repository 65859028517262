.container {
	display: block;
	position: absolute;
	transform: translateY(74px);
	right: 0;
	width: 300px;
	padding: 5px 20px;
	z-index: 99999999999;
	background: #fff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0px 2px 10px #00000027;
}

.container::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 8px solid white;
	left: -8px;
	top: 50px;
}

.iconContainer {
	position: absolute;
	display: block;
	transform: translateY(42px);
	right: 60px;
	z-index: 99999;
}
.groupNameItem {
	margin: 0;
	padding: 5px 15px;
	display: block;
}
.groupNameItem li {
	margin-top: 0.25rem;
	font: Roboto;
	font-size: 15px;
	text-align: left;
	letter-spacing: 0px;
}

.invalid:before {
}

.valid {
	list-style-type: none;
	color: var(--ion-color-primary);
}
.valid:before {
	content: '✓  ';
	position: absolute;
	left: 15px;
	width: 1em;
	height: 1em;
}
