.batteryDetailsContainer {
    width: 200px;
    height: 70px;
    z-index: 100;
    font-family: Roboto;
    display: block;
    color: #5c5956;
    font-size: 13px;
}
.batteryDetailsContainer .battSquare {
    width: 100%;
    height: 70px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
.batteryDetailsContainer .battSquare .squareGreenText {
    color: #56ae4d;
    width: 100%;
    font-weight: bold;
}
.batteryDetailsContainer .battSquare .squareGreenText span {
    color: #5c5956;
    font-weight: normal;
}
.batteryDetailsContainer .battSquare .squareGreenText .squareText {
    padding-bottom: 12px;
    margin-bottom: 12px;
    width: 100%;
}
.batteryDetailsContainer .battSquare .squareGreenText .battTriangle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    position: absolute;
    left: 30px;
}