
.list {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.list ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.list ion-item:hover {
    cursor: pointer;
}

.selectedItem {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
