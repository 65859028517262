.addModal {
	--border-radius: 10px;
}
.addForm {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.modalContent ion-row:first-child {
	flex-grow: 1;
}
.modalHeader {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}

.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.select {
	margin-top: 0.75rem;
}
.select div {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
}

.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}

.groupName {
	margin-top: 10px;
	font-weight: 400;
	color: var(--ion-color-gray-shade);
	font-size: 20px !important;
}

.groupContainer {
	padding-bottom: 5px;
	border-bottom: 1px solid var(--ion-color-gray-shade);
}

.from {
	color: var(--ion-color-gray-shade) !important;
}
