.outerContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.innerImgContainer {
	min-width: 156px;
	width: 156px;
}

.innerMsgContainer {
	margin: 12px 0;
	color: var(--ion-text-color-light);
    font-size: var(--ion-font-size);
}
