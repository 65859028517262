.buttonPrimary {
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 0.5px;

	width: 100%;
	--padding-end: 34px;
	--padding-start: 34px;

	border: 1px solid var(--ion-color-primary);
	border-radius: var(--ion-btn-border-radius);

	--color-hover: var(--ion-color-primary) !important;

	--background-hover: var(--ion-color-primary-contrast) !important;
	--background-hover-opacity: 1;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;

	--box-shadow: none !important;
}

@media (min-width: 576px) {
	.buttonPrimary {
		width: auto;
	}
}
