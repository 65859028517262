.forgotSpan {
	text-transform: none;
}
.hintContainer {
	margin-top: 0.875rem;
	margin-bottom: 2rem;
}
.hintContainer ion-label {
	white-space: pre-line !important;
}
.btnContainer {
	display: flex;
	width: fit-content;
	margin: auto;
}
.btnContainer ion-button {
	max-width: 300px;
	font-size: 18px;
	--padding: 0 0.5rem;
	--transition: 0.5s;
}
.backBtn {
	width: 168px;
	margin-right: 0.25rem !important;
}
.btnContainer ion-button:last-child {
	width: 212px;
	margin-left: 0.25rem !important;
}
.success {
	color: #56ae4d !important;
	padding: 10px;
	width: min-content;
}
.error {
	color: #9e0e0e !important;
	padding: 10px;
	width: min-content;
}
