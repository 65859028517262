body {
	outline: none;
	font-family: Roboto, Roboto Slab, Industry, Industry-Demi, Industry-Medium;
}

.containerMenuCollapsed {
	left: 110px !important;
}

.containerMenuExpanded {
	left: 290px !important;
}

.displayNone {
	display: none;
}
