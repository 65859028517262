.pre-session-animation {
	width: 100%;
	height: 100%;
	margin: 0px 0px;
	padding: 0px;
	align-self: center;
	flex-shrink: 1;
	// *******************************************************
	// fixme: this is a hack to get the animation to be displayed at the right size
	transform-origin: center;
	transform: scale(3.3);
	// *******************************************************
}
.listReadyForCallContainer {
	object-fit: contain;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.listPlayContainer {
	text-align: center;
	display: flex;
	justify-content: center;
}
.listPlayWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	transition: 0.3s;
}
.listGreenPlayWrapperShadow {
	position: absolute;
	transition: 0.2s;
	width: 0;
	height: 0;
	border-left: 30px solid white;
	border-top: 25px solid transparent;
	border-bottom: 25px solid transparent;
	margin-right: 20px;
}
.listGreenPlayWrapper {
	position: absolute;
	width: 22px;
	height: 25px;
	z-index: 100;
	transition: 0.3s;
	margin-right: 12px;
}
.listReadyForCallContainer:hover .listPlayWrapper {
	.listGreenPlayWrapper {
		width: 15px;
		height: 24px;
		margin-right: unset;
		transition: 0.2s;
		margin-left: 2px;
	}
	.listGreenPlayWrapperShadow {
		border-top: 0px solid transparent;
		border-bottom: 0px solid transparent;
		transition: 0.3s;
		transition-timing-function: ease-out;
		margin-right: unset;
	}
}
