.PhoneInput {
	height: 40px;
}

.PhoneInputInput {
	border: none;
}

.PhoneInputInput:focus {
	outline: none;
}
