@import url(https://themes.googleusercontent.com/fonts/css?kit=Ql-0kofkZ8ZK35GTMQezzyenZfp837hVvoP-hutgaAqdU2UdszIFv-JbwcPrcubs);
ul.lst-kix_8uromte14yar-7 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-6 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-2 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-2;
}
ul.lst-kix_8uromte14yar-8 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-3 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-2 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-5 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-4 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-1 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-0 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-2.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-2 0;
}
ol.lst-kix_vwawyprdpav-8.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-8 0;
}
.lst-kix_dkg1v55wbi2c-5 > li:before {
	content: '-  ';
}
.lst-kix_dkg1v55wbi2c-4 > li:before {
	content: '-  ';
}
.lst-kix_esf4yiq3lmjd-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_dkg1v55wbi2c-3 > li:before {
	content: '-  ';
}
.lst-kix_esf4yiq3lmjd-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_78hcq49ygbyk-4 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '. ';
}
.lst-kix_dkg1v55wbi2c-1 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-5 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '. ';
}
.lst-kix_dkg1v55wbi2c-0 > li:before {
	content: '-  ';
}
.lst-kix_dkg1v55wbi2c-2 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-7 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-7, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-6 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '. ';
}
.lst-kix_vwawyprdpav-4 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-4;
}
.lst-kix_78hcq49ygbyk-8 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-7, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-8, decimal) '. ';
}
ul.lst-kix_w4raey95u019-5 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-4 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-3 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-2 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-8 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-7 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-2.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-2 0;
}
ul.lst-kix_w4raey95u019-6 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-2 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-2;
}
ul.lst-kix_w4raey95u019-1 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-0 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-8 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-8;
}
ol.lst-kix_78hcq49ygbyk-8.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-8 0;
}
.lst-kix_78hcq49ygbyk-3 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-2 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '. ';
}
ul.lst-kix_99lgw0x0zg7c-6 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-6 > li:before {
	content: '\0025cf   ';
}
.lst-kix_78hcq49ygbyk-1 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '. ';
}
ul.lst-kix_99lgw0x0zg7c-7 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-4 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-5 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_99lgw0x0zg7c-5 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_99lgw0x0zg7c-8 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-0 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-6 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-6;
}
ul.lst-kix_esf4yiq3lmjd-0 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-2 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_esf4yiq3lmjd-1 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-0 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-0;
}
.lst-kix_esf4yiq3lmjd-1 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_esf4yiq3lmjd-2 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-3 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_esf4yiq3lmjd-3 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-2 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-4 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-3 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-5 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-0 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-6 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-1 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-7 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-8 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-0 > li:before {
	content: '\0025cf   ';
}
ol.lst-kix_78hcq49ygbyk-1.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-1 0;
}
ol.lst-kix_78hcq49ygbyk-7.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-7 0;
}
ol.lst-kix_vwawyprdpav-7.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-7 0;
}
ul.lst-kix_uvla8mc0uget-1 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-4.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-4 0;
}
ul.lst-kix_uvla8mc0uget-0 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-3 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-2 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-5 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-4 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-7 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-6 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-1.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-1 0;
}
.lst-kix_johatq9hcprj-1 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-3.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-3 0;
}
.lst-kix_johatq9hcprj-3 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-5 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-1 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-1;
}
ul.lst-kix_uvla8mc0uget-8 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-6.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-6 0;
}
.lst-kix_johatq9hcprj-7 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-5 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-5;
}
ol.lst-kix_vwawyprdpav-3.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-3 0;
}
.lst-kix_78hcq49ygbyk-4 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-4;
}
.lst-kix_vwawyprdpav-6 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-6;
}
.lst-kix_dkg1v55wbi2c-7 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-0 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-0;
}
ol.lst-kix_78hcq49ygbyk-4.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-4 0;
}
ul.lst-kix_dkg1v55wbi2c-1 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-2 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-0 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-5 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-1 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '. ';
}
ul.lst-kix_dkg1v55wbi2c-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-8 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_dkg1v55wbi2c-3 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-4 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-2 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'. ';
}
.lst-kix_vwawyprdpav-3 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '. ';
}
.lst-kix_vwawyprdpav-4 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '. ';
}
.lst-kix_vwawyprdpav-5 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-5;
}
.lst-kix_w4raey95u019-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_99lgw0x0zg7c-8 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-2 > li:before {
	content: '\0025a0   ';
}
.lst-kix_xzz4ue5r9r5k-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-7 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-1 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-1;
}
.lst-kix_99lgw0x0zg7c-7 > li:before {
	content: '-  ';
}
ul.lst-kix_f8umtzpnrzg4-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-8 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_w4raey95u019-7 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-3 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-4 > li:before {
	content: '-  ';
}
.lst-kix_99lgw0x0zg7c-5 > li:before {
	content: '-  ';
}
ul.lst-kix_f8umtzpnrzg4-2 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-5.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-5 0;
}
ul.lst-kix_dkg1v55wbi2c-7 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-5 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-8 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-4 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-0 > li:before {
	content: '\0025cf   ';
}
.lst-kix_w4raey95u019-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-0 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '. ';
}
.lst-kix_99lgw0x0zg7c-6 > li:before {
	content: '-  ';
}
.lst-kix_w4raey95u019-6 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_f8umtzpnrzg4-1 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-0 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-1 > li:before {
	content: '\0025cb   ';
}
.lst-kix_f8umtzpnrzg4-6 > li:before {
	content: '\0025cf   ';
}
ol.lst-kix_vwawyprdpav-5.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-5 0;
}
.lst-kix_f8umtzpnrzg4-4 > li:before {
	content: '\0025cb   ';
}
.lst-kix_f8umtzpnrzg4-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_f8umtzpnrzg4-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_xzz4ue5r9r5k-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_xzz4ue5r9r5k-6 > li:before {
	content: '\0025cf   ';
}
.lst-kix_f8umtzpnrzg4-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_uvla8mc0uget-6 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-6 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '. ';
}
.lst-kix_vwawyprdpav-7 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-7, decimal) '. ';
}
.lst-kix_uvla8mc0uget-7 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-0 > li:before {
	content: '\0025cf   ';
}
.lst-kix_vwawyprdpav-5 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'. ';
}
.lst-kix_vwawyprdpav-7 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-7;
}
.lst-kix_uvla8mc0uget-8 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-2 > li:before {
	content: '\0025a0   ';
}
.lst-kix_8uromte14yar-8 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-8 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-8 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-7, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-8, decimal)
		'. ';
}
.lst-kix_f8umtzpnrzg4-1 > li:before {
	content: '\0025cb   ';
}
.lst-kix_8uromte14yar-4 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-5 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-2 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-3 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-6 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-7 > li:before {
	content: '-  ';
}
.lst-kix_uvla8mc0uget-0 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-7 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-5 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-6 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-3 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-4 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-0.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-0 0;
}
.lst-kix_uvla8mc0uget-5 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-0 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-2 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-1 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-4 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-4 {
	list-style-type: none;
}
.lst-kix_8uromte14yar-0 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-1 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-0 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-3 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-6 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-5 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-1 > li:before {
	content: '-  ';
}
.lst-kix_uvla8mc0uget-3 > li:before {
	content: '-  ';
}
ol.lst-kix_vwawyprdpav-6.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-6 0;
}
ol.lst-kix_78hcq49ygbyk-8 {
	list-style-type: none;
}
.lst-kix_u755olrqzope-1 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-2 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-7 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-2 > li:before {
	content: '-  ';
}
ul.lst-kix_johatq9hcprj-1 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-7 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-0 > li:before {
	content: '-  ';
}
.lst-kix_99lgw0x0zg7c-1 > li:before {
	content: '-  ';
}
ul.lst-kix_johatq9hcprj-0 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-8 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-5 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-6 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-3 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-3 > li:before {
	content: '-  ';
}
ul.lst-kix_xzz4ue5r9r5k-4 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-1 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-0 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-2 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-1 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-3 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-3;
}
ul.lst-kix_u755olrqzope-2 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-0 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-3 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-4 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-1 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_u755olrqzope-5 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-0.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-0 0;
}
.lst-kix_99lgw0x0zg7c-2 > li:before {
	content: '-  ';
}
ul.lst-kix_u755olrqzope-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-2 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_u755olrqzope-7 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-8 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-0 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_johatq9hcprj-8 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-7 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-6 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-5 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-4 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-3 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-2 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-1 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-0 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-5 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-4 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-3 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-2 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-8 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-7 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-6 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-8 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-8;
}
.lst-kix_78hcq49ygbyk-7 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-7;
}
.lst-kix_johatq9hcprj-0 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-2 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-4 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-6 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-8 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_dkg1v55wbi2c-6 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-3 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-3;
}
.lst-kix_dkg1v55wbi2c-8 > li:before {
	content: '-  ';
}
ol {
	margin: 0;
	padding: 0;
}
table td,
table th {
	padding: 0;
}
.c3 {
	background-color: #ffffff;
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c0 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c12 {
	margin-left: 36pt;
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c8 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
	height: 11pt;
}
.c1 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 10pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c10 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 14pt;
	font-family: 'Quantico';
	font-style: italic;
}
.c14 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 10pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c21 {
	padding-top: 11pt;
	padding-bottom: 11pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c24 {
	padding-top: 0pt;
	padding-bottom: 10pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c18 {
	padding-top: 11pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c20 {
	padding-top: 11pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c6 {
	padding-top: 0pt;
	padding-bottom: 11pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c9 {
	padding-top: 0pt;
	padding-bottom: 10pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c2 {
	background-color: #ffffff;
	font-size: 10pt;
	font-family: 'Roboto';
	font-weight: 400;
}
.c15 {
	font-size: 10pt;
	font-family: 'Roboto';
	font-weight: 400;
}
.c17 {
	-webkit-text-decoration-skip: none;
	text-decoration: underline;
	text-decoration-skip-ink: none;
}
.c22 {
	color: inherit;
	text-decoration: inherit;
}
.c11 {
	padding: 0;
	margin: 0;
}
.c23 {
	font-weight: 700;
	font-family: 'Roboto';
}
.c7 {
	margin-left: 36pt;
	padding-left: 0pt;
}
.c25 {
	max-width: 468pt;
	padding: 64pt 72pt 72pt 72pt;
}
.c5 {
	margin-left: 72pt;
	padding-left: 0pt;
}
.c16 {
	padding-left: 0pt;
}
.c13 {
	margin-left: 36pt;
}
.c4 {
	background-color: #ffffff;
}
.c19 {
	margin-left: 72pt;
}
.title {
	padding-top: 0pt;
	color: #000000;
	font-size: 26pt;
	padding-bottom: 3pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.subtitle {
	padding-top: 0pt;
	color: #666666;
	font-size: 15pt;
	padding-bottom: 16pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
li {
	color: #000000;
	font-size: 11pt;
	font-family: 'Arial';
}
p {
	margin: 0;
	color: #000000;
	font-size: 11pt;
	font-family: 'Arial';
}
h1 {
	padding-top: 20pt;
	color: #000000;
	font-size: 20pt;
	padding-bottom: 6pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h2 {
	padding-top: 18pt;
	color: #000000;
	font-size: 16pt;
	padding-bottom: 6pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h3 {
	padding-top: 16pt;
	color: #434343;
	font-size: 14pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h4 {
	padding-top: 14pt;
	color: #666666;
	font-size: 12pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h5 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h6 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	font-style: italic;
	orphans: 2;
	widows: 2;
	text-align: left;
}
