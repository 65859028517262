.selectContainer {
	margin: 0 0.5em 0.5em 0.5em;
}

.width {
	width: 9px;
}

.hidden {
	display: none;
}

.alignCenter {
	text-align: center;
}

.fullWidth {
	width: 100%;
}
.fixedWidth {
	max-width: 50%;
}
.addModal {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.addForm {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.modalContent ion-row:first-child {
	flex-grow: 1;
}
.modalHeader {
	display: block;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.icon {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.subTitle {
	font-family: 'Industry';
	font-size: 18px !important;
	text-transform: capitalize;
}
.formList {
	width: 100%;
	margin: 0 3rem;
}
.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.iconContainer {
	width: max-content;
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.addRobotToTransferBtnContainer {
	border-style: solid;
}

.leftSide {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.rightSide {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.createBtnContainer,
.inviteBtnContainer {
	display: flex;
	align-items: center;
	padding-bottom: 1.375rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.inviteBtnContainer {
	padding-top: 1.375rem;
}
.createBtn,
.inviteBtn {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.inviteIcon {
	margin-right: 0.5rem;
}
.inviteLb {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}

.nameContainer {
	display: flex;
	align-items: center;
}
.noPic {
	color: var(--ion-text-color);
}
/* Create modal */
.addModal {
	--width: 613px;
	--height: 640px;
	--border-radius: 10px;
}
.addModalHeight {
	--width: 613px;
	--height: 733px;
	--border-radius: 10px;
}
.addForm {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 50px;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	margin-top: 20px;
}
.modalContent ion-row:first-child {
	flex-grow: 1;
}
.modalHeader {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.headerHint {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.usersRow {
	margin-top: 3.2rem;
	margin-left: 0.5rem;
}
.devicesRow ion-col {
	flex: 1;
	overflow-y: auto;
}
.devicesRow ion-col:first-child {
	padding-right: 2.4rem;
}
.devicesList,
.noSelection {
	overflow-y: auto;
	max-height: 300px;
	padding: 5px !important;
}
.noSelection {
	margin-top: 0.9rem;
}
.noSelection ion-label {
	color: var(--ion-color-gray);
}
.noDevice {
	display: flex;
	margin-top: 0.9rem;
	color: var(--ion-color-gray);
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.colTitle {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.searchBar {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.searchBar ion-icon {
	color: var(--ion-text-color) !important;
}
.searchBar input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.deviceItem {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	background-color: var(--ion-color-primary-contrast);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.robotPicContainer {
	width: 62px;
	height: 38px;
	margin-left: 0.375rem;
}
.robotPic {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	margin-left: 0.3em;
}
.deviceName {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
}
.selectBtn {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.selectBtn ion-icon.fabIcon {
	width: 1rem;
	height: 1rem;
}
.hidden {
	display: none;
}
.select {
	margin-top: 0.75rem;
	margin-bottom: 1rem;
}
.select div {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
}

.from {
	color: var(--ion-color-gray-shade) !important;
}

.required {
	color: var(--ion-color-primary) !important;
}
