.addModal {
    --width: 613px;
    --height: 640px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    min-height: 233px;
    --border-radius: 10px;
}
.modalContent {
    background: var(--ion-color-primary-contrast);
    display: flex;
    flex-direction: column;    
    align-items: center;
    height: 100%;
}
.modalHeader {
    display:block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0;
}
.headerContainer {
    width: 100%;
}
.headerTitle {
    font-family: 'Industry'!important;
    font-size: 28px!important;
    margin-top: 3rem;
    margin-bottom: 1.875rem;
    display: block;
    text-align: center;
}
.subTitle {
    color: var(--ion-color-gray-shade);
}
/* .editImageContainer {
	width: 321px;
	margin: 24px auto 6px;
	height: calc(53px + 6px);
	--inner-padding-end: 0;
} */
.msgContainer {
    flex: 1;
    margin-top: 1.5rem;
    width: 60%;
    color: var(--ion-color-primary-contrast);
}
.fullImage {
	min-width: 200px;
	min-height: 200px;
}
.userAvatar {
	margin: auto !important;
	height: 200px;
    width: 200px;
    /* background-color: var(--ion-text-color); */
}
.tabContainer {
	--background: var(--ion-color-primary-contrast);
	border-radius: 5px;
	border: 1px solid var(--ion-color-gray-shade);
	display: inline-flex;
	width: 100%;
    height: 57px;
    margin-bottom: 2.75rem;
}
.tabContainer ion-segment-button,
.tabContainer .urlExpand {
    --color: var(--ion-color-gray-shade);
	--indicator-box-shadow: none;
	--padding-bottom: 15px;
	--padding-top: 16px;
	--padding-left: 2px !important;
    --padding-right: 2px !important;
    --background-hover: var(--ion-main-background-color);
    --background-hover-opacity: 1;
    --transition: 0.5s;
	border-radius: 0px;
    margin: 0;
}
.urlExpand {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.iconContainer {
	width: max-content;
}
.icon {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.iconExpanded {
    color: var(--ion-color-gray-shade);
    width: 26px;
	height: 26px;
    margin: 14px 7px 10px 16px;
}
/* .tabContainer ion-segment-button:first-child {
	border-right: 1px solid var(--ion-color-gray-shade);
} */
.tabContainer ion-segment-button:last-child {
	border-left: 1px solid var(--ion-color-gray-shade);
}
.tabContainer ion-icon {
  width: 26px;
	height: 26px;
  margin-right: 0.5rem;
}
.iconExpanded {
    color: var(--ion-color-gray-shade);
    width: 26px;
      height: 26px;
    margin: 14px 7px 10px 16px;
}
.btnContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 130px;
}
.btnContainer ion-button {
    --border-width: 1px;
    --background-hover-opacity: 1;
    --transition: 0.5s;
    width: 185px;
    height: 50px;
    font-size: 15px;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
}
.btnContainer ion-button:first-child {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.saveBtn {
    --color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
    --background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}
.hidden {
	display: none;
}