.session-popup-blocked-modal {
	background-color: rgba(0, 0, 0, 0.65);
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 2000;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
}

.session-popup-blocked-modal .session-popup-blocked-modal-inner {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	padding: 0px 16px;
	max-width: 648px;
}

.session-popup-blocked-modal .time-remaining {
	font-style: italic;
	color: #505050;
}

.session-popup-blocked-modal .message-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	flex-grow: 1;
	flex-shrink: 1;
	min-height: 64px;
	margin: 18px 0px;
	overflow-y: auto;
}

.session-popup-blocked-modal .message-container .main-message {
	font-weight: 700;
}

.session-popup-blocked-modal .dont-show-again-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.session-popup-blocked-modal img.popup-blocked-tutorial {
	object-fit: contain;
	object-position: center;
	align-self: center;
	/* border: 0.5px solid gray; */
	border-radius: 2px;
	margin: 12px 0px 8px;
}

.session-popup-blocked-modal .dont-show-again-container > input {
	margin-right: 4px;
}

.session-popup-blocked-modal .session-popup-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 0px 16px;
	margin-top: 16px;
	border-top: 1px solid gray;
}

.session-popup-blocked-modal .pill-button {
	width: 150px;
	padding: 8px 0px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0px 6px;
}

.session-popup-blocked-modal .button-okay {
	border: solid 1px #56ae4d;
	background-color: #56ae4d;
	color: white;
}

.session-popup-blocked-modal .button-cancel {
	border: solid 1px #111111;
	background-color: #ffffff;
	color: black;
}
