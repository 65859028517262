/* .usersContent {
	height: 100%;
	display: flex;
	flex-flow: column;
	padding: 0 !important;
}
ion-list {
	padding: 0 !important;
}
.inviteUserModal {
	height: 813px;
    width: 613px;
}
.addForm {
	height: 100%;
	margin: 20px 90px 90px!important;
}
.formGrid {
	display: flex;
	flex-direction: column;
	height: inherit;
	overflow-y: auto;
}.formGrid ion-row:first-child {
	flex-grow: 1;
}
.addList {
	width: 100%;
	padding-bottom: 0;
}
.groupSelect,
.deviceSelect,
.organizationSelect {
	margin: 10px 10px 10px!important;
	border-style: hidden hidden solid hidden 2px!important;
}
.inviteList {
	/* margin: 6rem 6.875rem; */
/* }
.inviteHeader {
	display: flex;
    flex-direction: column;
}
.inviteUserHint {
	width: 244px;
	height: 20px;
	opacity: 0.5;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: var(--ion-background-menu-item-hover);
}

.inviteUserTitle {
	text-align: left;
	width: 100%;
	height: 33px;
	font-family: Industry-Demi;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.04;
	letter-spacing: normal;
	color: var(--ion-color-primary);
}

.headerIcon {
	color: var(--ion-color-primary);
}

.organizationSelect {
	font-family: Roboto;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-text-color);
	margin: 10px 10px 10px!important;
}*/

.selectContainer {
	margin: 0 0.5em 0.5em 0.5em;
}

.width {
	width: 9px;
}

.hidden {
	display: none;
}

.alignCenter {
	text-align: center;
}

.fullWidth {
	width: 100%;
}

.addModal {
	--width: 613px;
	--border-radius: 10px;
}
.addForm {
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.modalContent ion-row:first-child {
	flex-grow: 1;
}
.modalHeader {
	display: flex;
    flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
    margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.icon {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.iconExpanded {
    color: var(--ion-color-gray-shade);
    width: 26px;
	height: 26px;
    margin: 14px 7px 10px 16px;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.headerHint {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.formList {
	width: 100%;
	margin: 0 3rem;
}
.nameRow {
    margin-top: 0.8rem;
}
.select {
	margin-top: 0.75rem;
}
.select div {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.selectLb {
	font-size: 13px!important;
    margin-left: 0.5rem;
}
.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.iconContainer {
	width: max-content;
}
.chkRow {
	padding: 0 3rem;
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}