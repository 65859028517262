.tabContainer {
	--background: var(--ion-color-primary-contrast);
	padding: 0.5rem;
	justify-content: flex-start;
}
.tabBtn {
	padding: 0.875rem;
	max-width: 13.5rem;
	/* font-size: 15px; */
	--color-checked: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-text-color);
	--indicator-color: var(--ion-color-primary);
}
.tabIcon {
	margin-right: 0.5rem;
}

.tabIconOrganizations {
	margin-right: 0.5rem;
	color: var(--ion-color-primary);
}
.tabTitle {
	text-transform: uppercase;
}

.tabTitleOrganizations {
	color: grey;
	text-transform: uppercase;
}

.passedItem {
	text-transform: uppercase;
	color: var(--ion-color-primary);
}

.selectedItem {
	text-transform: uppercase;
	color: black;
}

.tabContent {
	display: flex;
	flex-grow: 1;
	margin-top: 1.113rem;
	margin-bottom: 1.5rem;
}
