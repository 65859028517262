.nameContainer {
	display: flex;
	align-items: center;
}
.userAvatar {
	display: flex;
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.profilePic {
	width: 38px;
	height: 38px;
}
.noPic {
	color: var(--ion-text-color);
}
.userNameLb {
	justify-content: flex-start;
	flex-grow: 1;
}
.roleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.shieldIcon {
	width: 18px;
	height: 18px;
}
.groupNames {
	text-align: left;
}
.groupNameItem {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.groupNameItem li {
	margin-top: 0.25rem;
}
.groupLink {
	justify-content: center;
}
.groupLink:hover {
	text-decoration: underline;
	width: 100%;
}
.noData {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.noDataHint {
	font-weight: normal;
	margin-bottom: 1rem;
}
