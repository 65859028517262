.header {
	font-family: 'Industry-Demi';
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 48px;
	text-align: center;
	text-transform: capitalize;
	padding-top: 0;
	padding-bottom: 30px;
}
.cancelBtn {
	color: var(--ion-color-primary);
	background: var(--ion-color-primary-contrast);
	text-transform: none;
}
.cancelBtn:hover {
	color: var(--ion-color-primary-contrast);
	background: var(--ion-color-primary);
}
.submitBtn {
	color: var(--ion-color-primary-contrast);
	background: var(--ion-color-primary);
	text-transform: none;
}
.submitBtn:hover {
	color: var(--ion-color-primary);
	background: var(--ion-color-primary-contrast);
}
.disabledSubmitBtn {
	color: var(--ion-color-primary-contrast) !important;
	background: var(--ion-color-gray);
	text-transform: none;
}
.reservationMsgContainer {
	margin-top: 20px;
}
.reservationCheckingMsg {
	color: var(--ion-color-gray);
	display: flex;
	align-items: center;
}
.reservationAvailableMsg {
	color: var(--ion-color-primary);
	display: flex;
	align-items: center;
}
.reservationCheckingMsg ion-icon,
.reservationAvailableMsg ion-icon,
.reservationUnavailableMsg ion-icon {
	margin-right: 5px;
}
.reservationUnavailableMsg {
	color: var(--ion-color-danger);
	display: flex;
	align-items: center;
}
.inputLabel {
	display: flex;
	align-items: center;
	flex-wrap: 'wrap';
	gap: 5px;
}
.icon {
	font-size: 25px;
}

