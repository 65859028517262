.pre-session-animation {
	width: 100%;
	margin: 0px 0px;
	padding: 0px;
	align-self: center;
	flex-shrink: 1;
	// *******************************************************
	// fixme: this is a hack to get the animation to be displayed at the right size
	transform-origin: center;
	transform: scale(2.3);
	// *******************************************************
}
.readyForCallContainer {
	object-fit: contain;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.goBePlayContainer {
	text-align: center;
	display: flex;
	justify-content: center;
}
.goBePlayWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	transition: 0.3s;
}
.greenPlayWrapperShadow {
	position: absolute;
	transition: 0.2s;
	width: 0;
	height: 0;
	border-left: 50px solid #242525;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	margin-right: 40px;
}
.greenPlayWrapper {
	position: absolute;
	width: 60px;
	height: 60px;
	z-index: 100;
	transition: 0.3s;
	margin-right: 40px;
}
