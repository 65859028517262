.formItemContainer {
	margin: 0 0.5em 0.5em;
}
.formItem {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}
.formItem:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}
.formItem:active {
	--highlight-background: var(--form-field-active-color);
}
.formItem ion-label {
	margin-bottom: 10px;
}
.formItem ion-label[position='floating'] {
	margin-bottom: 8px;
}
.formItem ion-label.lbColor {
	color: var(--ion-text-color) !important;
}
.formItem .colorFocused,
.formItem ion-label.colorFocused {
	color: var(--ion-color-primary) !important;
}
.formItem .colorError,
.formItem ion-label.colorError {
	color: var(--form-field-error-color) !important;
}
.formItem ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}
.errorItem {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}
.errorMsg {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}
.inputIcon {
	display: inline-flex !important;
	width: 100%;
}
.inputIconSize {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 50000s ease-in-out 0s;
}
