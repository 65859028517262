.mainTitle {
    font-family: 'Industry';
    font-size: 28px !important;
}

.tabContentGrid {
    padding: 0;

}

.setupTabBtn {
    --color-checked: var(--ion-color-primary);
    --indicator-color: transparent;
    --indicator-box-shadow: none;
    padding: 0.25rem 0;
}

.contentGrid {
    padding: 0;
}

.leftSide {
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin-right: 1rem;
    padding-left: 0;
}

.rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1rem 2.5rem;
    height: 560px;
}

.triangle {
    right: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}

.hide {
    display: none;

}

.list {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.list ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.list ion-item:hover {
    cursor: pointer;
}

.selectedItem {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.leftCol {
    max-width: 22.5rem;
}

.btnCol {
    display: flex;
    justify-content: flex-end;
    padding-right:0px;
}

.btn {
    height: 50px;
    --border-width: 1px;
    --border-style: solid;
    --background: transparent;
    --background-hover-opacity: 1;
    --box-shadow: none;
    --transition: 0.5s;
}

.backBtn {
    max-width: 9.5rem;
    --color: var(--ion-text-color);
    --color-hover: var(--ion-color-primary-contrast);
    --border-color: var(--ion-text-color);
    --background-hover: var(--ion-text-color);
}

.backBtn ion-icon {
    padding-bottom: 0.1rem;
}

.nextBtn {
    width: 11.563rem;
    --color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
    --background-hover: var(--ion-main-background-color);
}

.buttonsColRight {
    padding-right:0px;
}