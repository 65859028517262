.privacyPolicyModal {
	--width: 613px;
}
.agreementContainer {
	width: 100%;
	height: 600px;
	max-height: 600px;
	overflow-y: scroll;
}
.buttonContainer {
	display: flex;
	justify-content: center;
	padding: 34px 0;
}
