@media (max-width: 576px) {
	.loginGrid .topLogoContainer {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.loginFormContainer {
		align-items: flex-start !important;
	}
	.loginFormContainer ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.loginGrid .logo {
		align-self: flex-end;
	}
	.loginForm {
		width: 100%;
		max-width: none !important;
		padding: 0 20px 20px !important;
	}
	.loginForm .btnRow {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.loginForm .btnRow ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.loginForm .btnRow ion-button {
		max-width: 100% !important;
		margin: none !important;
	}
	.blueoceanLogo {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.loginGrid .topLogoContainer {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.loginFormContainer {
		align-items: flex-start !important;
	}
	.loginFormContainer ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.loginGrid .logo {
		align-self: flex-end;
	}
	.loginForm {
		width: 100%;
		max-width: none !important;
		padding: 0 20px 20px !important;
	}
	.loginForm .btnRow {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.loginForm .btnRow ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.loginForm .btnRow ion-button {
		max-width: 100% !important;
		margin: none !important;
	}
	.blueoceanLogo {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}

.loginGrid,
.loginGridRow {
	height: 100%;
}
.loginGrid .leftContainer {
	height: 100%;
}
.loginGrid .homeCover {
	display: block;
	width: 100%;
	height: 100%;
	background: var(--login-page-image);
	background-size: cover;
}

.loginGrid .homeCover.uvd {
	background: url('/assets/img/GoBe_Cover.png'); /*change me*/
	background-size: cover;
}

.loginGrid .homeCover.beam {
	background: url('/assets/img/beam-cover.png');
	background-size: cover;
}

.loginGrid .homeCover.gobe {
	background: url('/assets/img/GoBe_Cover.png');
	background-size: cover;
}

.loginGrid .rightContainer {
	display: flex;
	flex-direction: column;
}
.loginFormContainer {
	flex-grow: 1;
	padding-top: 40px;
}
.loginGrid ion-button {
	display: flex;
	text-transform: none;
}
.loginGrid .logoItem {
	--border-color: white;
}
.loginGrid .logo {
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;

	display: block;
	width: 100%;
	min-height: 104px;
	margin-bottom: 0.8em;
	background: var(--robot-logo);
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
}

.loginGrid .logo.uvd {
	background: url('/assets/img/gobe-robots-logo.svg'); /*change me*/
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
}

.loginGrid .welcomeLb {
	width: 100%;
	color: var(--ion-color-gray);
}
.loginForm {
	padding: 20px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.loginForm ion-row {
	padding-top: 1.15em;
	margin: 0 auto;
}
.loginForm .loginList {
	width: 100%;
}
.loginForm .btnRow ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.loginForm .btnRow ion-button {
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.forgotPswLink {
	color: var(--ion-color-primary);
}
.forgotPswLink:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
.errorContainer {
	color: var(--ion-color-danger);
}
.dividerContainer {
	/* width: 70%; */
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.dividerContainer div:nth-of-type(1) {
	flex-grow: 1;
}
.dividerContainer div:nth-of-type(3) {
	flex-grow: 1;
}
.divider {
	height: 2px;
	background-color: var(--ion-background-color-divider);
}
.dividerFont {
	margin-left: 20px;
	margin-right: 20px;
	color: var(--ion-background-color-divider);
}
.logoContainer {
	width: 109px;
	height: 67px;
}
.blueoceanLogo {
	width: calc(100% - 30px);
	height: 38px;
	margin: 9px 15px 20px;
}
.loginBtn,
.backBtn {
	font-size: 18px;
	max-width: 185px;
	--border-width: 1px;
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}

.loginSSOBtn {
	font-size: 18px;
	max-width: 135px;
	padding: 0px;
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.ssoElem {
	margin-right: 0.5em !important;
	margin-left: 0.5em !important;
}
.ssoRow {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 0px;
	padding-top: 0px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.ssoPadding {
	padding-top: 42px !important;
	padding-bottom: 0px !important;
}
.ssoRow ion-button {
	margin: auto;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	text-transform: uppercase;
	--background-activated: var(--ion-color-secondary);
}

.loginRow {
	padding-top: 0px !important;
}
.noAccountAction {
	width: 100%;
	text-align: center;
	margin: 32px 0;
}
.ssoTxt {
	position: absolute;
	right: 8px;
}
.ssoIcon {
	position: absolute;
	left: 2px;
}
.ssoLoginBtnRow ion-col {
	padding-left: 1.5em;
	margin-right: 0em;
}
.ssoLoginBtnRow ion-button {
	margin-right: 0em;
	margin-left: 2em;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.provideEmailFont {
	color: var(--secondary);
}
.hintContainer {
	margin-top: 0.875rem;
	margin-bottom: 2rem;
}
.hintContainer ion-label {
	white-space: pre-line !important;
}
.success {
	color: #56ae4d !important;
	padding: 10px;
	width: min-content;
}
.error {
	color: #9e0e0e !important;
	padding: 10px;
	width: min-content;
}
