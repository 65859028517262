.addModal {
	--width: 613px;
	--height: 640px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	min-height: 233px;
	--border-radius: 10px;
}
.modalContent {
	background: var(--ion-text-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	opacity: 0.9;
	padding: 2rem;
	grid-gap: 1rem;
}
.modalHeader {
	display: block;
	text-align: center;
	margin-top: 2rem;
	margin-bottom: 1rem;
	padding: 0;
}
.headerContainer {
	text-align: center;
}
.deleteModalHeaderContainer {
	text-align: left;
}
.headerTitle {
	font-family: 'Industry' !important;
	font-size: 35px !important;
	margin-bottom: 1.875rem;
}
.msgContainer {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--ion-color-primary-contrast);
	--background: var(--ion-text-color);
	overflow-y: auto;
}
.msgContainer .msg {
	font-size: 1.2rem !important;
}
.btnContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	--background: var(--ion-text-color);
}
.btnContainer ion-button {
	--color: var(--ion-color-primary-contrast);
	--border-width: 1px;
	--background-hover-opacity: 1;
	--transition: 0.5s;
	width: 185px;
	height: 50px;
	font-size: 15px;
	margin-block: 1.2rem;
}
.btnContainer ion-button:first-child {
	margin-left: 0.5rem;
}
.cancelBtn {
	--color-hover: var(--ion-text-color) !important;
	--border-color: var(--ion-color-primary-contrast);
	--background: var(--ion-text-color);
	--background-hover: var(--ion-color-primary-contrast) !important;
	margin-right: 0.5rem;
}
.deleteBtn {
	--color-hover: var(--ion-color-danger);
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-text-color);
	--border-color: var(--ion-color-danger);
}
