/* Bootstrap Media Query Breakpoints

/* Extra Small Devices, Phones equivalent to class xs */
@media only screen and (min-width: 480px) {
	.hideXs {
		display: none;
	}
	.hideSm {
		display: inline;
	}
	.hideMd {
		display: inline;
	}
	.hideLg {
		display: inline;
	}
}

/* Small Devices, Tablets equivalent to class sm */
@media only screen and (min-width: 768px) {
	.hideXs {
		display: inline;
	}
	.hideSm {
		display: inline;
	}
	.hideMd {
		display: inline;
	}
	.hideLg {
		display: inline;
	}
}

/* Medium Devices, Desktops equivalent to class md */
@media only screen and (min-width: 992px) {
	.hideXs {
		display: inline;
	}
	.hideSm {
		display: inline;
	}
	.hideMd {
		display: none;
	}
	.hideLg {
		display: inline;
	}
}

/* Large Devices, Wide Screens equivalent to class lg */
@media only screen and (min-width: 1200px) {
	.hideXs {
		display: inline;
	}
	.hideSm {
		display: inline;
	}
	.hideMd {
		display: inline;
	}
	.hideLg {
		display: none;
	}
}
