.addModal {
	--border-radius: 10px;
	--height: calc(100% - 4rem);
	--max-height: 900px;
}
.addForm {
	height: 100%;
	width: 100%;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	height: 50%;
}

.modalHeader {
	display: block;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 12px;
	padding: 0;
}
.modalContentContainer {
	padding: 0 2rem;
    height: calc(100% - 95px);
    overflow-y: auto;
}
.modalContentContainerAllocate {
	height: 40vh;
	max-height: 400px;
    overflow-y: auto;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.tabContainer {
	--background: var(--ion-color-primary-contrast);
	/* padding: 0 0.5rem; */
	margin: 0 3rem;
	border-radius: 100px;
	border: 2px solid #dddddd;
}
.tabContainer ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.tabContainer ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.formList {
	margin: 0 3rem;
	height: 100%;
}
.rowContainer {
	width: 100%;
	display: block;
	padding: 0;
}
.btnContainer {
	height: 95px;
}
.formContainer {
	width: 100%;
	height: 100%;
}
.zipCityContainer {
	display: flex;
	justify-content: space-between;
}

.zipCityContainer > *  {
	width: 45%;
}

.countryContainer {
	display: inline!important;
	height: auto;
	width: auto;
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
	padding: 0 2.3rem;
}
.btnRowAllocate {
    display: flex;
	align-items: flex-end;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitBtn {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
	margin-left: 0.5rem;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.searchBar {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.searchBar ion-icon {
	color: var(--ion-text-color) !important;
}
.searchBar input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.robotsGrid {
	width: 100%;
	margin: 3rem 3rem 2.5rem;
}
.robotsGrid ion-col:first-child {
	margin-right: 1rem;
}
.robotsGrid ion-col:last-child {
	margin-left: 1rem;
}
.colTitle {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.noSelection {
	margin-top: 0.9rem;
}
.noSelection ion-label {
	color: var(--ion-color-gray);
}
.robotItem {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	background-color: var(--ion-color-primary-contrast);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.robotPicContainer {
	width: 62px;
	height: 38px;
	background-color: var(--ion-text-color);
	margin-left: 0.375rem;
}
.robotPic {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	background-color: var(--ion-text-color);
	background: var(--robot-logo-white);
	background-repeat: no-repeat;
	background-position: left;
	margin-left: 0.3em;
}
.robotName {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
}
.selectRobotBtn {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.selectRobotBtn ion-icon.fabIcon {
	width: 1rem;
	height: 1rem;
}
.hidden {
	display: none;
}
.allocateContainer {
	height: 100%;
}
.required{
    color: var(--ion-color-primary);
}