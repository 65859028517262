.leftSide {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.rightSide {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.btnContainer {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.addToGroupBtnContainer {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.inviteBtn,
.addToGroupBtn {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.inviteIcon {
	margin-right: 0.5rem;
}
.inviteLb {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}
.addIcon {
	font-size: 2rem;
}
/* more popup */
.itemContent {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.moreList tr:last-child .itemContent {
	padding-bottom: 0.375rem;
}
.itemLb {
	color: var(--ion-more-lb-color);
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
	text-transform: capitalize;
}
.itemLb:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
.invitedUser {
	display: flex;
	margin: 1rem 0.3rem 1rem 0;
	color: var(--ion-color-gray-shade);
}
.invitedUser ion-icon {
	margin-right: 0.5rem;
}
.invitedUser span {
	text-transform: capitalize;
	margin-right: 0.3rem;
	display: contents;
}

.selectUsersContainer {
	padding: 5px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.disabledSelectUserBtn {
	--color: white;
	--border-color: var(--ion-text-color);
	--background: var(--ion-color-primary);
	--border-width: 1px;
	opacity: 0.25;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.selectUsersBtn {
	--color: white;
	--border-color: var(--ion-color-primary) !important;
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.modalHeader {
	flex-direction: column;
}

.selectedUsersContainer {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 30px;
	max-height: 170px;
	overflow-y: scroll;
}

.selectedUsersContainer::-webkit-scrollbar {
	width: 8px;
}

.selectedUsersContainer::-webkit-scrollbar-track {
	border-radius: 10px;
}

.selectedUsersContainer::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px 6px var(--ion-color-gray-tint);
}

.userContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	height: 30px;
	margin: 2px 5px;
	border-radius: 5px;
	background-color: var(--ion-main-background-color);
}

.deleteUserBtn {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-color-gray);
	--box-shadow: none;
	height: 50px;
	width: 50px;
	margin: 0 -10px;
}
