.leftSide {
    display: flex;
    flex-direction: column;
    min-width: 22.5rem;
    margin-right: 1.5rem;
}
.rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 2.5rem;
}
.rightSide:after {
    right: 100%;
    top: 12%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    /* border-color: rgba(136, 183, 213, 0); */
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}
.adminNote {
    font-size: 0.813rem;
    margin-right: 0.25rem;
}
.recentContainer {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 0.5rem;
}
.recentLb {
    font-family: 'Industry';
    font-weight: bold;
    margin-left: 0.5rem;
}
.recentContainer ion-list {
    width: 100%;
}
.recentContainer .profilePic {
    width: 2.5rem;
    height: 2.5rem;    
}
.recentContainer ion-avatar {
    margin-right: 0.5rem;
}
.recentContainer .icon {
    color: var(--ion-text-color);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.recentContainer .info, .recentContainer .timeIcon {
    color: var(--ion-color-gray-shade);
}
.header {
    display: flex;
}
.editBtnContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.editBtn {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    --color: var(--ion-color-primary-contrast);
    margin-right: 1rem;
}
.editable {
    --background: var(--ion-color-primary);
    opacity: 1;
}
.btnContainer {
    display: flex;
    justify-content: flex-end;
}
.detailsBtn {
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);

}
.formBtns {
    display: flex;
}
.formBtns ion-icon {
    color: var(--ion-color-gray);
}
.formBtns ion-icon:hover {
    cursor: pointer;
}
.hidden {
    display: none!important;
}
.savedLb {
    color: var(--ion-color-primary);
    font-size: 13px;
    margin-left: 0.5rem;
}
.moreNames {
    display: flex!important;
}
.andLb {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.moreInLb:hover {
    text-decoration: underline;
    cursor: pointer;
}
.groupNameLb {
    display: flex;
}
.groupNameItem {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;
}
.groupNameItem li {
    margin-top: 0.25rem;
}