.container {
	margin: 0 0.5em 0.5em;
	position: relative;
	z-index: 2;
}

.containerStandardInput {
	position: static;
	max-width: 300px;
}

.containerNoFloat {
	margin: 0.5em;
}

.containerMarginLess {
	margin: 0;
}

.containerStandardInput {
	width: 100%;
	margin: 0;
}

.controller {
	border-bottom: 1px solid var(--ion-color-gray-tint);
}

.controller:hover {
	border-bottom: 1px solid var(--ion-color-gray-shade);
}

.containerError .controller {
	border-bottom: 1px solid var(--form-field-error-color);
}

.containerStandardInput .controller {
	border: 1px solid var(--form-field-hover-color);
	border-radius: 4px;
	padding-left: 0.5em;
}

.label {
	position: relative;
	top: 15px;
	transition: top 0.2s;
	color: var(--ion-text-color);
	font-size: 15px;
	font-weight: normal;
}

.containerDisabled .label {
	opacity: 0.3;
}

.containerSelected .label {
	top: 0;
	font-size: 11px;
}

.containerNoFloat .label {
	top: 0;
}

.requiredIndicator {
	padding: 0 2px;
	color: var(--ion-color-primary);
}

.containerDisabled .requiredIndicator {
	color: var(--ion-text-color);
	opacity: 0.3;
}

.errorMessage {
	margin: 4px 0 0;
	font-size: 13px;
	color: var(--form-field-error-color);
}
