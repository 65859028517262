.main {
	height: 100%;
	display: flex;
	flex-flow: column;
	padding: 0 !important;
}
.orgSelect {
	font-family: 'Industry';
	font-size: 28px;
	border-radius: 5px;
	border: solid 1px #d2d2d2;
	padding-right: 1rem;
	height: 65px;
}
.orgSelect::part(text) {
	padding-right: 0.5rem;
}
.orgSelect::part(icon)
{
	/* display: none; */
}
.fabBtnContainer {
	position: relative;
	display: flex;
    z-index: 0;
}
.deleteFabBtn {
	margin-left: 0.5rem!important;
}
.addFabBtn, .editFabBtn, .deleteFabBtn {
    --background: #dddddd;
    --color: var(--ion-text-color);
    --box-shadow: none;
	border-radius: 50%;
	max-width: 37px;
	max-height: 37px;
    margin: 0 0 0 0.5rem;
}
.fabIcon {
    color: #777779;
}