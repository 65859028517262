.emptyListWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}
.emptyListText {
	font-family: roboto;
	font-size: 17px;
	line-height: 24px;
	color: #242525;
	margin-left: 16px;
	margin-top: 0px;
}
