.selectField {
    border-radius: 5px;
    margin-right: 8px;
    color: var(--ion-text-color); /*#6d6e70;*/
    min-width: 120px;
}
.selectField span {
    width: 0;
}
.selectField .menuList:first-child {
    background-color: red!important;
}
.selectLabel {
    color: var(--ion-text-color)!important;
    margin-left: 10px!important;
}
.errorMsg {
    color: var(--form-field-error-color);
    font-size: 0.8em;
    width: 100%;    
    display: block;
    padding-top: 5px;
    padding-left: 8px;
}