.sliderContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 0px;
	width: 100%;
	padding: 0px;
}
.inputR {
	-webkit-appearance: none;
	appearance: none;
	width: 150px;
	height: 6px;
	border-radius: 2px;
	opacity: 1;
	outline: none;
	cursor: pointer;
}

.inputR::-webkit-slider-thumb {
	appearance: none;
	-webkit-appearance: none;
	background: #56ae4d;
	height: 25px;
	width: 25px;
	border-radius: 50%;
}
.cursorointer {
	cursor: pointer;
}
