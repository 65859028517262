.contentContainer {
	height: 100%;
	min-height: 100%;
	box-sizing: border-box;
	padding-top: 64px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.confirmationContent ion-icon {
    font-size: 100px;
}

.confirmationContent h2 {
	padding: 0;
	margin: 28px 0;
	font-family: 'Industry';
	font-size: 28px !important;
	color: --ion-text-color;
}

.confirmationContent p {
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: 18px !important;
	color: --ion-text-color;
	text-align: center;
	max-width: 80%;
}

.confirmationContent .headerContainer {
    margin-bottom: 15vh;
}

.formContainer {
	flex: 1 1 auto;
	max-width: 475px;
}

.formContainer ion-row {
	height: 74px;
}

.formContainerError ion-row {
	height: 84px;
}

.disclaimer {
	margin: 42px 0 12px;
	color: var(--ion-text-color-light);
	font-size: var(--ion-font-size);
}

.privacyPolicyButton {
	display: inline;
	color: var(--ion-color-primary);
	cursor: pointer;
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonsContainer a {
	font-size: var(--ion-font-size);
	text-decoration: none;
}

.confirmationContainer {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footerContainer {
	width: 100%;
}
