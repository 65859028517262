.icon {
	margin-right: 0.5rem;
}

/* Organization details */
.formGrid {
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
}

.formGrid ion-list {
	width: 100%;
	padding: 0;
}

.formGrid .firstCol {
	margin-right: 5rem;
	padding-left: 16px;
}

.formGrid ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.formGrid ion-list-header {
	margin-top: 1.5rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.detailHeader {
	font-family: 'Industry-Demi';
}

.selectContainer {
	margin: 0 0.5em 0.5em 0.5em;
}

.msgContainer {
	flex: 1;
	margin-top: 1.5rem;
	width: 100%;
	color: var(--ion-color-primary-contrast);
}

.fullImage {
	border-radius: 0;
	width: 120px;
	height: 120px;
	object-fit: contain !important;
	margin: auto
}

.avatarClass {
	display: flex;
	background-color: var(--ion-text-color);
	border-radius: 0px;
	height: 200px;
	width: 185px;
	margin: auto;
}

.urlExpand {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.iconContainer {
	width: max-content;
}

.icon {
	width: 15.8px;
	height: 19.8px;
	margin-right: 8px;
}

.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.hidden {
	display: none;
}

.createdOnLb {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.profileListHeader {
	--background: transparent !important;
}

.profileHeader {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

/* Billing and shipping*/
.billingShippingGrid {
	margin: 0;
}

.billingShippingGrid ion-row.header {
	margin-top: 1.5rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.billingShippingGrid ion-row.header ion-col {
	padding: 0;
}

.billingShippingGrid ion-row.header ion-item {
	--background: transparent;
}

.billingShippingGrid ion-row.header ion-label {
	font-family: 'Industry-Demi';
	text-transform: uppercase;
}

.billingShippingGrid .chkContainer .detailHeader {
	display: contents;
}

.sameAsLb {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.sameAsChk {
	margin-left: 0.5rem;
}

.billingList {
	margin-right: 2rem;
}

.zipCityContainer {
	display: flex;
}

.zipCityContainer div {
	width: 100%;
}

.formItemContainer {
	margin: 0 0.5em 0.5em;
}

.formItem {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.formItem:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.formItem:active {
	--highlight-background: var(--form-field-active-color);
}

.formItem ion-label {
	margin-bottom: 10px;
}

.formItem ion-label.lbColor {
	color: var(--ion-text-color) !important;
}

.formItem .colorFocused,
.formItem ion-label.colorFocused {
	color: var(--ion-color-primary) !important;
}

.formItem .hasFocus,
.formItem ion-label.hasFocus {
	color: var(--ion-color-primary) !important;
}

.formItem ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.errorItem {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.errorMsg {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.inputIcon {
	display: inline-flex !important;
	width: 100%;
}

.inputIconSize {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.robotTimeZoneItem{
	margin-top:5px
}

.robotTimeZoneSelect{
	margin-top:5px;
	width:100%
}

.inGroupLabel{
	
	font-size:14px;
}

.hasNoRobot{
	margin-top:10px;
	color:#a5a5a6;
	
}

.createBtn{
	width:138px;
	height:53px;
	text-transform: initial;
	height: 45px;
	width: 150px;
}

.initialText{
	text-transform: initial;

}
.spanContainer{
	min-height:20px;
}

.linkContainer {
    display: flex;
	margin-top: 3rem;
	margin-left: 16px
}
.linkContainer ion-icon {
    color: var(--ion-color-primary);
}
.link {
    color: var(--ion-color-primary);
}
.link:hover, .linkContainer ion-icon:hover {
    color: var(--ion-color-primary-shade);
    cursor: pointer;
}

.IonChip{
	background-color:#eeeeee ;
	color:#1e1f22
}

.close{
	background-color: #a5a5a6;
	color:#eeeeee;
	border-radius: 50%;
	
}

.close:hover{
	background:#1e1f22
}



.devicesRow{
	max-height: 120px;
    overflow: auto;
}

.groupNameItem {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.groupNameItem li {
	margin-top: 0.25rem;
}
.andLb {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}

.moreInLb:hover {
	text-decoration: underline;
	cursor: pointer;
}

.tooltipIonChip{
	margin-left:30px
}

.IonChipLabel{
	margin-right:20px
}
.addIcon{
	font-size: 1.5rem;
	margin-right: 10px;
}