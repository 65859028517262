.media {
  object-fit: contain;
  pointer-events: none;
  transform-style: flat;
  position: absolute;
  left: 0px;
  top: 0px;
  box-sizing: content-box;
}

.cropConfigContainer{
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: aliceblue;
  padding: 0 25px 25px 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}