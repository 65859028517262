.leftSide {
	display: flex;
	flex-direction: column;
	min-width: 22.5rem;
	margin-right: 1.5rem;
	overflow: hidden;
}
.rightSide {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background-color: var(--ion-color-primary-contrast);
	position: relative;
	border-radius: 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 2.5rem;
	overflow: auto;
}
.rightSide:after {
	right: 100%;
	top: 12%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	/* border-color: rgba(136, 183, 213, 0); */
	border-right-color: var(--ion-color-primary-contrast);
	border-width: 14px;
	margin-top: -14px;
}
.adminNote {
	font-size: 0.813rem;
	margin-right: 0.25rem;
}
.recentContainer {
	display: flex;
	flex-grow: 1;
	width: 100%;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 0.5rem;
}
.recentLb {
	font-family: 'Industry';
	font-weight: bold;
	margin-left: 0.5rem;
}
.recentContainer ion-list {
	width: 100%;
}
.recentContainer .profilePic {
	width: 2.5rem;
	height: 2.5rem;
}
.recentContainer ion-avatar {
	margin-right: 0.5rem;
}
.recentContainer .icon {
	color: var(--ion-text-color);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.recentContainer .info,
.recentContainer .timeIcon {
	color: var(--ion-color-gray-shade);
}
.header {
	display: flex;
	align-items: center;
}
.editBtnContainer {
	flex-grow: 1;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.editBtn {
	--padding-top: 1rem;
	--padding-bottom: 1rem;
	--padding-start: 0.5rem;
	--padding-end: 0.5rem;
	--color: var(--ion-color-primary-contrast);
	margin-right: 0.5rem;
}
.editable {
	--background: #377831;
	opacity: 1;
}
.btnContainer {
	display: flex;
	justify-content: flex-end;
}
.tabContainer {
	--background: var(--ion-color-primary-contrast);
	border-radius: 100px;
	border: 2px solid #dddddd;
	max-width: 480px;
	width: fit-content;
	height: 36px;
	justify-content: right;
}
.tabContainer ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.tabContainer ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.detailsBtn {
	max-width: 110px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.reservationsBtn {
	display: flex;
	flex: 1 1 auto;
	max-width: 140px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.accessHoursBtn {
	display: flex;
	flex: 1 1 auto;
	max-width: 140px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.networkBtn {
	max-width: 110px;
}
.healthBtn {
	max-width: 110px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.iconMargin {
	margin: 0 5px;
}
.formBtns {
	display: flex;
}
.formBtns ion-icon {
	color: var(--ion-color-gray);
}
.formBtns ion-icon:hover {
	cursor: pointer;
}
.hidden {
	display: none !important;
}
.savedLb {
	color: var(--ion-color-primary);
	font-size: 13px;
	margin-left: 0.5rem;
}
.moreNames {
	display: flex !important;
}
.andLb {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}
.moreInLb:hover {
	text-decoration: underline;
	cursor: pointer;
}
.groupNameLb {
	display: flex;
}
.groupNameItem {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.groupNameItem li {
	margin-top: 0.25rem;
}
.avatarClass {
	display: flex;
	justify-content: center;
	border-radius: 0;
	width: 5.063rem;
	height: 100%;
	margin: 1.68rem 0.5rem 2rem 0.5rem;
}
.avatarClass img {
	width: 100%;
	height: 100%;
	border-radius: 0 !important;
}
.editAvatarClass {
	display: flex;
	justify-content: center;
	border-radius: 0;
	width: 11.563rem;
	height: 100%;
	margin: 0 !important;
}
.editAvatarClass img {
	width: 100%;
	min-width: auto;
	min-height: auto;
	border-radius: 0 !important;
}
.segmentContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	overflow-y: scroll;
	overflow: hidden;	
}
.segmentContent ion-row:first-child {
	flex-grow: 1;
}
.detailsContainer {
	width: 100%;
}
.trash {
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	margin-right: 2px !important;
}
.btnMargin {
	margin-right: 1rem;
}
.serviceInfoIcon {
	font-size: x-large;
	padding-top: 5px;
}
.serviceInfoSubIcon {
	position: relative;
	top: 4px;
	padding-right: 5px;
}
.greyLabel {
	color: grey !important;
}
.serviceInfoItem {
	border-bottom: 1px solid #f5f5f5;
	padding-top: 10px;
	padding-bottom: 10px;
}
.batteryPercentage {
	padding-left: 10px;
}
.uvdStatus {
	padding-top: 10px;
}
.reservationContainer {
	margin-top: 20px;
}
.accessHoursContainer {
	margin-top: 20px;
	width: 100%;
}
.timeslotsBar {
	display: flex !important;
	flex-direction: row !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
	border-top: 1px solid #000000 !important;
}
.timeslotBar {
	min-height: 12px;
	border-radius: 5px;
	height: 24px;
	/* position: absolute; */
	/* left: 160px; */
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
	padding-right: 5px;
	margin-top: 2px;
	margin-bottom: 2px;
}
.dayNameText {
	min-width: 100px;
	max-width: 100px;
	margin-right: 20px
}
.whiteText {
	color: #FFFFFF;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
}
