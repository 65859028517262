.wrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 15%;
}

.line {
	flex-grow: 1;
	height: 2px;
	background-color: #b2b2b2;
}

.footerImageWrapper {
	margin-bottom: 14px;
}

.blueOceanLogo {
	margin: 15px 15px 28px;
}