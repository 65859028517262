.searchBar {
	--placeholder-color: var(--ion-color-gray-tint);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
	padding: 0;
}
.searchBar ion-icon {
	color: var(--ion-text-color) !important;
}
.searchBar button {
	right: 0.3rem !important;
}
.searchBar input {
	padding-left: 0.5rem !important;
	border: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
	padding-inline-end: 35px !important;
	max-height: 36px !important;
	border-radius: 5px !important;
}
